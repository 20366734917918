import React, { useContext } from 'react';

import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/subPartners/list.module.scss';
import useOfficialPartnersPage from 'data/queries/useOfficialPartnersPage';
import PartnerMenu from 'components/subPartners/partnerMenu';
import PartnerBoxes from 'components/subPartners/partnerBoxes';
import Banner from 'components/misc/Banner';
import { getFormattedMessage } from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

const OfficialPartners = () => {
    const { lang } = useContext(LangContext);
    const data = useOfficialPartnersPage();

    return (
        <div>
            {data.bannerImage && data.title && (
                <Banner
                    image={data.bannerImage.image}
                    title={getFormattedMessage('partners.ourPartners', lang)}
                />
            )}
            <div className={`${style.partnerList__content} wrapper`}>
                <PartnerMenu
                    activePartner="officialPartners"
                />
                <main>
                    {lang?.hreflang && data?.title[lang.hreflang] ? (
                        <h2 className="title-sm-mobile title-lg text-semibold">
                            {data.title[lang.hreflang]}
                        </h2>
                    ): null}

                    {data.description && data.description[lang.translationKey] ? (
                        <div className={style.partnerList__description}>
                            <TextEditorRender content={data.description[lang.translationKey]._rawContent} />
                        </div>
                    ): null}

                    {data.officialPartnersMen.length !== 0 && (
                        <PartnerBoxes
                            headline={getFormattedMessage('partners.teamMen', lang)}
                            boxSize="lg"
                            partners={data.officialPartnersMen}
                        />
                    )}

                    {data.officialPartnersWomen.length !== 0 && (
                        <PartnerBoxes
                            headline={getFormattedMessage('partners.teamWomen', lang)}
                            boxSize="lg"
                            partners={data.officialPartnersWomen}
                        />
                    )}
                </main>
            </div>
        </div>
    );
};

export default OfficialPartners;
